
/*
SharedFizz page override
*/
.org-lovepop .shared-fizz-view-page .header-branding {
  box-shadow: none;
}
.org-lovepop .shared-fizz-view-page .header-branding img {
  height: 60px;
}
.org-lovepop .shared-fizz-view-page main.container {
    border-top: none;
}




/* 
* LP Header
*/
.lovepop-nav .list-menu {
  display: inline-flex;
  width: 100%;
  padding: 0 0 20px 0;
  margin: 0;

  flex-wrap: wrap;
  list-style: none;
  
  justify-content: center;

  border-bottom: 0.1rem solid rgba(18, 18, 18, 0.08);
}

.lovepop-nav .list-menu li {
  padding: 0 10px;
}
.lovepop-nav li .header__menu-item::marker {
  content: '';
}


.lovepop-nav .header__menu-item svg{
  margin-left: 4px;
  display: inline-block;
  width: 13px;
  color: #ce3537;
}

.lovepop-nav a, .lovepop-nav a:visited, .lovepop-nav a:link {
  color: rgb(18, 18, 18);
  display: inline-block;
  font-family: -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", blueprint-icons-16, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 18.1953px;
  letter-spacing: -0.3px;
  line-height: 18.2px;
  text-decoration: none;
  text-transform: none;
  text-underline-offset: 3px;
}
.lovepop-nav a:hover {
  text-decoration: underline;
}
.lovepop-nav .mega-menu__content{
  display: none;
}


/* 
LP Footer
*/
.lovepop-option {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #F2F2F2;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 4px;
  margin-bottom: 16px;
  height: 95%;
}
.lovepop-option a, .lovepop-option a:link {
  color: #000;
  text-decoration: none;
}
.lovepop-option a:hover {
  text-decoration: underline;
}

.lovepop-hover-image {
  width: 100%;
  aspect-ratio: 1;
}
.lovepop-hover-image img {
  width: 100%;
}
.lovepop-hover-image .hover {
  display: none;
}
.lovepop-hover-image:hover .main {
  display: none;
}
.lovepop-hover-image:hover .hover {
  display: inline;
}