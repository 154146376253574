
@media (min-width: 768px) { /* md */ 
  .min-height-desktop {
    min-height: 30vh;
  }
}


.fizz-clip {
  position: relative;
  width: 100%;
  display: flex;
}

.clip-actions{
  flex-grow:1;
}
.clip-upload-bar {
  flex-grow:1;
  padding-right: 3%;
}

.clip-thumb {
  position: relative;
  width: 100px;
  aspect-ratio: 16/9;
  z-index: 1;
  cursor: pointer;
}
.clip-thumb img {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: black;
}
.fizz-clip-uploading .clip-thumb img {
  object-fit: contain;
  background: black;
}

.clip-sound.themed-icon-wrap {
  width: 20px;
  height: 18px;
}

.clip-drag, .clip-delete, .clip-sound {
  cursor: pointer;
}


/* Mobile borders and rows */
@media (max-width: 991px) {

  .fizz-clip {
    background-color: #fff;
    border-bottom: 4px solid #d9d9d9;
    border-top: 4px solid #d9d9d9;
    margin-top: -4px;
  }
  .clip-actions{
    text-align: right;
    line-height: 56px;
  }
  .clip-actions i{
    width: 18px;
    height: 22px;
  }
  .clip-drag {
    padding-left: 5px;
    padding-right: 5px;
    height: 56px;
  }
  .clip-drag i {
    font-size: 0;
    width: 30px;
    height: 18px;
  }
  .clip-delete, .clip-sound {
    margin-right: 15px;
  }
  .clip-delete.themed-icon-wrap, .clip-sound.themed-icon-wrap {
    height:56px;
  }
}

/* DESKTOP CLIPS */
@media (min-width: 992px) {

  .fizz-clip {
    aspect-ratio: 16/9;
    width: 25%;
    padding-left: 0.5%;
    padding-right: 0.5%;
    margin-bottom: 1.333%;
  }

  .clip-actions .themed-icon-wrap {
    background-color: white;
  }

  .clip-upload-bar {
    position: absolute;
    padding: 0;
    left: 5%;
    right: 5%;
    bottom: 0;
    z-index: 2;
  }
  .clip-upload-bar span {
    display: none;
  }

  .clip-actions i.themed-icon-wrap {
    background-color: white;
  }
  .clip-drag {
    display: none;
  }
  .clip-thumb {
    width: 100%;
  }
  .clip-thumb img {
    width: 100%;
    border-radius: 8px;
  }
  .clip-delete {
    position: absolute;
    bottom: 7%;
    right: 6%;
    width: 10%;
    height: auto;
    z-index: 2;
  }
  .clip-sound {
    position: absolute;
    bottom: 9%;
    right: 20%;
    width: 10%;
    height: auto;
    z-index: 2;
  }
}


.desktop-add-button {  
  /* See fizz-clip above */
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16/9;
  width: 25%;
  min-height:20px;
  height: auto;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-bottom: 1.333%;
  position: relative;
}

/* Mobile borders and rows */
@media (max-width: 991px) {

  .desktop-add-button {  
    display: none;
  }

}
.desktop-add-button::before {
    content: "";
    position: relative;
    width: 100%;
    border-radius: 8px;
    background-color: #EBEBEB;
    padding-top: 56.25%;
    top: -2px;
}
.desktop-add-button::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: var(--primary);
    background-image: url(../images/vf-icons/plus-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 100%;
    z-index: 0;
}
.desktop-add-button:hover {
  cursor: pointer;
}
/* Mobile borders and rows */
@media (max-width: 991px) {
  .desktop-add-button.d-flex {
    display: none !important;
  }
}
.sound-picker-list .active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.upload-count-container {
  position: fixed;
  top: 60%;
  left: 0%;
  width: 100%;
  z-index: 1000;
  text-align: center;
}

.upload-count {
  background-color: #fff;
  line-height: 1;
  margin: 5px 0;
  font-weight: bolder;
  border: 1px solid gray;
  border-radius: 10px;
  height: 100px;
  padding: 30px;
}


